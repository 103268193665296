@tailwind base;
@tailwind components;
@tailwind utilities;

/* Font */
@import url('https://fonts.googleapis.com/css?family=Space+Grotesk');
@import url('https://fonts.googleapis.com/css?family=Poppins');

/* In src/index.css */
@import url('https://unpkg.com/leaflet/dist/leaflet.css');


.text-space-grotesk {
  font-family: 'Space Grotesk', sans-serif;
}


body {
  font-family: "Poppins", sans-serif;
}

/* Navigation Header */
.navbar-zindex {
  z-index: 10000;
}

.iconChange:after {
  content: "";
  display: block;
  margin: auto;
  height: 3px;
  width: 0px;
  background: transparent;
  transition: all .5s ease
}

.iconChange:hover:after,
.iconChange.selected:after {
  width: 100%;
  background: #1a5fb4
}

.iconHeight {
  height: 35px;
  width: 35px
}

.iconChange:hover a {
  color: #1a5fb4
}

.iconChange img {
  transition: all .5s
}

.iconChange:hover img,
.iconChange.selected img {
  transform: translate(-150%);
  transform-origin: right;
  transition: transform .5s ease-in-out
}

.changeWidth {
  width: 50%
}

.overflow-hidden {
  overflow: hidden !important
}

/* Utiltties */
/* blinking */
@keyframes fadeInOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.blinking {
  animation: fadeInOut 2s infinite;
}


/* Bunkering Animation */

.pipeFlow1 {
  stroke-dasharray: 1120;
  animation: draw1 5s linear, disappear 0s linear 10s, reappear 0s linear 11s;
  animation-iteration-count: infinite;
}

@keyframes draw1 {
  0% {
    stroke-dashoffset: 1120;
  }

  80% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes disappear {
  100% {
    stroke-opacity: 0;
  }
}

@keyframes reappear {
  100% {
    stroke-opacity: 1;
  }
}

/* blink */

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.blink {
  animation: blink 3s infinite;
}

/* table loading */


.tg {
  border-collapse: collapse;
  border-spacing: 0;
  border-color: #ccc;
}

.tg td {
  font-family: Arial, sans-serif;
  font-size: 14px;
  border-style: solid;
  border-width: 0px;
  overflow: hidden;
  word-break: normal;
  border-color: #ccc;
  color: #333;
  background-color: #fff;
}

.line {
  float: left;
  width: 100%;
  height: 30px;
  border-radius: 7px;
  background-image: linear-gradient(90deg, #f4f4f4 0px, rgba(229, 229, 229, 0.8) 40px, #f4f4f4 80px);
  background-size: 600px;
  animation: shine-lines 2s infinite ease-out;
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 140px;
  }
}

/* table loading */


.tg {
  border-collapse: collapse;
  border-spacing: 0;
  border-color: #ccc;
}

.tg td {
  font-family: Arial, sans-serif;
  font-size: 14px;
  border-style: solid;
  border-width: 0px;
  overflow: hidden;
  word-break: normal;
  border-color: #ccc;
  color: #333;
  background-color: #fff;
}

.line {
  float: left;
  width: 100%;
  height: 30px;
  border-radius: 7px;
  background-image: linear-gradient(90deg, #f4f4f4 0px, rgba(229, 229, 229, 0.8) 40px, #f4f4f4 80px);
  background-size: 600px;
  animation: shine-lines 2s infinite ease-out;
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 140px;
  }
}

