.leftToRight {
    animation: dash 200s linear infinite;
    animation-name: leftToRight;
}

.rightToLeft {
    animation: dash 200s linear infinite;
    animation-name: rightToLeft;
}

@keyframes leftToRight {
    from {
        stroke-dashoffset: 822;
    }

    to {
        stroke-dashoffset: 0;
    }
}

@keyframes rightToLeft {
    from {
        stroke-dashoffset: 0;
    }

    to {
        stroke-dashoffset: 822;
    }
}

