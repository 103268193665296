.neutron-scada .side-nav {
    width: 230px;
    padding-right: 1.25rem;
    padding-bottom: 4rem;
    overflow-x: hidden
}

@media (max-width: 1279px) {
    .neutron-scada .side-nav {
        width: 85px
    }
}

@media (max-width: 767px) {
    .neutron-scada .side-nav {
        display: none
    }
}

.neutron-scada .side-nav.side-nav--simple {
    width: 85px
}

.neutron-scada .side-nav.side-nav--simple .side-menu .side-menu__title,
.neutron-scada .side-nav.side-nav--simple .side-menu .side-menu__title .side-menu__sub-icon {
    display: none
}

.neutron-scada .side-nav.side-nav--simple .side-menu:not(.side-menu--active) .side-menu__icon:before {
    display: none
}

.neutron-scada .side-nav .side-nav__divider {
    width: 100%;
    height: 1px;
    background: #ffffff14;
    z-index: 10;
    position: relative
}

.neutron-scada .side-nav .side-menu {
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 1.25rem;
    margin-bottom: .25rem;
    position: relative;
    /* border-radius: 9999px */
}

.neutron-scada .side-nav .side-menu .side-menu__title {
    width: 100%;
    margin-left: .75rem;
    display: flex;
    align-items: center
}

.neutron-scada .side-nav .side-menu .side-menu__title .side-menu__sub-icon {
    margin-left: auto;
    margin-right: 1.25rem;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(.4, 0, 1, 1);
    transition-duration: .1s
}

.neutron-scada .side-nav .side-menu .side-menu__title .side-menu__sub-icon svg {
    width: 1rem;
    height: 1rem
}

@media (max-width: 1279px) {

    .neutron-scada .side-nav .side-menu .side-menu__title .side-menu__sub-icon,
    .neutron-scada .side-nav .side-menu .side-menu__title {
        display: none
    }
}

.neutron-scada .side-nav>ul>li:nth-child(1).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .1s
}

.neutron-scada .side-nav>ul>li:nth-child(1)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .1s
}

.neutron-scada .side-nav>ul>li:nth-child(1)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(2).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .2s
}

.neutron-scada .side-nav>ul>li:nth-child(2)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .2s
}

.neutron-scada .side-nav>ul>li:nth-child(2)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(3).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(3 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(3)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(3 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(3)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(4).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .4s
}

.neutron-scada .side-nav>ul>li:nth-child(4)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .4s
}

.neutron-scada .side-nav>ul>li:nth-child(4)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(5).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .5s
}

.neutron-scada .side-nav>ul>li:nth-child(5)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .5s
}

.neutron-scada .side-nav>ul>li:nth-child(5)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(6).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(6 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(6)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(6 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(6)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(7).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(7 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(7)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(7 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(7)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(8).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .8s
}

.neutron-scada .side-nav>ul>li:nth-child(8)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .8s
}

.neutron-scada .side-nav>ul>li:nth-child(8)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(9).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .9s
}

.neutron-scada .side-nav>ul>li:nth-child(9)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .9s
}

.neutron-scada .side-nav>ul>li:nth-child(9)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(10).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1s
}

.neutron-scada .side-nav>ul>li:nth-child(10)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1s
}

.neutron-scada .side-nav>ul>li:nth-child(10)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(11).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.1s
}

.neutron-scada .side-nav>ul>li:nth-child(11)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.1s
}

.neutron-scada .side-nav>ul>li:nth-child(11)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(12).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(12 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(12)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(12 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(12)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(13).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.3s
}

.neutron-scada .side-nav>ul>li:nth-child(13)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.3s
}

.neutron-scada .side-nav>ul>li:nth-child(13)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(14).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(14 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(14)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(14 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(14)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(15).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.5s
}

.neutron-scada .side-nav>ul>li:nth-child(15)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.5s
}

.neutron-scada .side-nav>ul>li:nth-child(15)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(16).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.6s
}

.neutron-scada .side-nav>ul>li:nth-child(16)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.6s
}

.neutron-scada .side-nav>ul>li:nth-child(16)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(17).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(17 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(17)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(17 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(17)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(18).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.8s
}

.neutron-scada .side-nav>ul>li:nth-child(18)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.8s
}

.neutron-scada .side-nav>ul>li:nth-child(18)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(19).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(19 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(19)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(19 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(19)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(20).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2s
}

.neutron-scada .side-nav>ul>li:nth-child(20)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2s
}

.neutron-scada .side-nav>ul>li:nth-child(20)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(21).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.1s
}

.neutron-scada .side-nav>ul>li:nth-child(21)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.1s
}

.neutron-scada .side-nav>ul>li:nth-child(21)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(22).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.2s
}

.neutron-scada .side-nav>ul>li:nth-child(22)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.2s
}

.neutron-scada .side-nav>ul>li:nth-child(22)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(23).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(23 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(23)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(23 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(23)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(24).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(24 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(24)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(24 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(24)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(25).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.5s
}

.neutron-scada .side-nav>ul>li:nth-child(25)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.5s
}

.neutron-scada .side-nav>ul>li:nth-child(25)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(26).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.6s
}

.neutron-scada .side-nav>ul>li:nth-child(26)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.6s
}

.neutron-scada .side-nav>ul>li:nth-child(26)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(27).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.7s
}

.neutron-scada .side-nav>ul>li:nth-child(27)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.7s
}

.neutron-scada .side-nav>ul>li:nth-child(27)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(28).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(28 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(28)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(28 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(28)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(29).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(29 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(29)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(29 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(29)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(30).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3s
}

.neutron-scada .side-nav>ul>li:nth-child(30)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3s
}

.neutron-scada .side-nav>ul>li:nth-child(30)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(31).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.1s
}

.neutron-scada .side-nav>ul>li:nth-child(31)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.1s
}

.neutron-scada .side-nav>ul>li:nth-child(31)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(32).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.2s
}

.neutron-scada .side-nav>ul>li:nth-child(32)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.2s
}

.neutron-scada .side-nav>ul>li:nth-child(32)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(33).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(33 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(33)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(33 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(33)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(34).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(34 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(34)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(34 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(34)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(35).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.5s
}

.neutron-scada .side-nav>ul>li:nth-child(35)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.5s
}

.neutron-scada .side-nav>ul>li:nth-child(35)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(36).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.6s
}

.neutron-scada .side-nav>ul>li:nth-child(36)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.6s
}

.neutron-scada .side-nav>ul>li:nth-child(36)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(37).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.7s
}

.neutron-scada .side-nav>ul>li:nth-child(37)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.7s
}

.neutron-scada .side-nav>ul>li:nth-child(37)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(38).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(38 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(38)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(38 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(38)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(39).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(39 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(39)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(39 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(39)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(40).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4s
}

.neutron-scada .side-nav>ul>li:nth-child(40)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4s
}

.neutron-scada .side-nav>ul>li:nth-child(40)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(41).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(41 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(41)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(41 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(41)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(42).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.2s
}

.neutron-scada .side-nav>ul>li:nth-child(42)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.2s
}

.neutron-scada .side-nav>ul>li:nth-child(42)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(43).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.3s
}

.neutron-scada .side-nav>ul>li:nth-child(43)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.3s
}

.neutron-scada .side-nav>ul>li:nth-child(43)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(44).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.4s
}

.neutron-scada .side-nav>ul>li:nth-child(44)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.4s
}

.neutron-scada .side-nav>ul>li:nth-child(44)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(45).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.5s
}

.neutron-scada .side-nav>ul>li:nth-child(45)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.5s
}

.neutron-scada .side-nav>ul>li:nth-child(45)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(46).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(46 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(46)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(46 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(46)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(47).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.7s
}

.neutron-scada .side-nav>ul>li:nth-child(47)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.7s
}

.neutron-scada .side-nav>ul>li:nth-child(47)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(48).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(48 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(48)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(48 * .1s)
}

.neutron-scada .side-nav>ul>li:nth-child(48)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(49).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.9s
}

.neutron-scada .side-nav>ul>li:nth-child(49)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.9s
}

.neutron-scada .side-nav>ul>li:nth-child(49)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul>li:nth-child(50).side-nav__divider {
    opacity: 0;
    animation: .4s intro-divider-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 5s
}

.neutron-scada .side-nav>ul>li:nth-child(50)>a:not(.side-menu--active) {
    opacity: 0;
    transform: translate(50px);
    animation: .4s intro-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 5s
}

.neutron-scada .side-nav>ul>li:nth-child(50)>a.side-menu--active {
    animation: .4s intro-active-menu-animation ease-in-out .33333s;
    animation-fill-mode: forwards
}

.neutron-scada .side-nav>ul ul li:nth-child(1)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .1s
}

.neutron-scada .side-nav>ul ul li:nth-child(2)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .2s
}

.neutron-scada .side-nav>ul ul li:nth-child(3)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(3 * .1s)
}

.neutron-scada .side-nav>ul ul li:nth-child(4)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .4s
}

.neutron-scada .side-nav>ul ul li:nth-child(5)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .5s
}

.neutron-scada .side-nav>ul ul li:nth-child(6)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(6 * .1s)
}

.neutron-scada .side-nav>ul ul li:nth-child(7)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(7 * .1s)
}

.neutron-scada .side-nav>ul ul li:nth-child(8)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .8s
}

.neutron-scada .side-nav>ul ul li:nth-child(9)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: .9s
}

.neutron-scada .side-nav>ul ul li:nth-child(10)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1s
}

.neutron-scada .side-nav>ul ul li:nth-child(11)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.1s
}

.neutron-scada .side-nav>ul ul li:nth-child(12)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(12 * .1s)
}

.neutron-scada .side-nav>ul ul li:nth-child(13)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.3s
}

.neutron-scada .side-nav>ul ul li:nth-child(14)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(14 * .1s)
}

.neutron-scada .side-nav>ul ul li:nth-child(15)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.5s
}

.neutron-scada .side-nav>ul ul li:nth-child(16)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.6s
}

.neutron-scada .side-nav>ul ul li:nth-child(17)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(17 * .1s)
}

.neutron-scada .side-nav>ul ul li:nth-child(18)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 1.8s
}

.neutron-scada .side-nav>ul ul li:nth-child(19)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(19 * .1s)
}

.neutron-scada .side-nav>ul ul li:nth-child(20)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2s
}

.neutron-scada .side-nav>ul ul li:nth-child(21)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.1s
}

.neutron-scada .side-nav>ul ul li:nth-child(22)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.2s
}

.neutron-scada .side-nav>ul ul li:nth-child(23)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(23 * .1s)
}

.neutron-scada .side-nav>ul ul li:nth-child(24)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(24 * .1s)
}

.neutron-scada .side-nav>ul ul li:nth-child(25)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.5s
}

.neutron-scada .side-nav>ul ul li:nth-child(26)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.6s
}

.neutron-scada .side-nav>ul ul li:nth-child(27)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 2.7s
}

.neutron-scada .side-nav>ul ul li:nth-child(28)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(28 * .1s)
}

.neutron-scada .side-nav>ul ul li:nth-child(29)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(29 * .1s)
}

.neutron-scada .side-nav>ul ul li:nth-child(30)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3s
}

.neutron-scada .side-nav>ul ul li:nth-child(31)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.1s
}

.neutron-scada .side-nav>ul ul li:nth-child(32)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.2s
}

.neutron-scada .side-nav>ul ul li:nth-child(33)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(33 * .1s)
}

.neutron-scada .side-nav>ul ul li:nth-child(34)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(34 * .1s)
}

.neutron-scada .side-nav>ul ul li:nth-child(35)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.5s
}

.neutron-scada .side-nav>ul ul li:nth-child(36)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.6s
}

.neutron-scada .side-nav>ul ul li:nth-child(37)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 3.7s
}

.neutron-scada .side-nav>ul ul li:nth-child(38)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(38 * .1s)
}

.neutron-scada .side-nav>ul ul li:nth-child(39)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(39 * .1s)
}

.neutron-scada .side-nav>ul ul li:nth-child(40)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4s
}

.neutron-scada .side-nav>ul ul li:nth-child(41)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(41 * .1s)
}

.neutron-scada .side-nav>ul ul li:nth-child(42)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.2s
}

.neutron-scada .side-nav>ul ul li:nth-child(43)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.3s
}

.neutron-scada .side-nav>ul ul li:nth-child(44)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.4s
}

.neutron-scada .side-nav>ul ul li:nth-child(45)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.5s
}

.neutron-scada .side-nav>ul ul li:nth-child(46)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(46 * .1s)
}

.neutron-scada .side-nav>ul ul li:nth-child(47)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.7s
}

.neutron-scada .side-nav>ul ul li:nth-child(48)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: calc(48 * .1s)
}

.neutron-scada .side-nav>ul ul li:nth-child(49)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 4.9s
}

.neutron-scada .side-nav>ul ul li:nth-child(50)>a {
    opacity: 0;
    transform: translate(50px);
    animation: .2s intro-submenu-animation ease-in-out .33333s;
    animation-fill-mode: forwards;
    animation-delay: 5s
}

.neutron-scada .side-nav>ul ul {
    display: none;
    background-color: #0000001a;
    border-radius: .5rem
}

.neutron-scada .side-nav>ul ul.side-menu__sub-open {
    display: block;
    margin-left: 17px;
    /* margin-top: 30px; */

}

.neutron-scada .side-nav>ul ul .side-menu__icon:before {
    display: none
}

.neutron-scada .side-nav>ul ul li a:not(.side-menu--active) {
    color: #ffffffb3
}

.neutron-scada .side-nav>ul ul li a.side-menu--active .side-menu__title {
    font-weight: 500
}

.neutron-scada .side-nav>ul ul ul {
    display: none;
    background: #0000001a;
    border-radius: .5rem
}

.neutron-scada .side-nav>ul>li>.side-menu.side-menu--active {
    background-color: #f1f5f9
}

.neutron-scada .side-nav>ul>li>.side-menu.side-menu--active:before {
    /* content: "";
    width: 30px;
    height: 30px;
    margin-top: -30px;
    transform: rotate(90deg) scale(1.04);
    background-size: 100%;
    background-image: url("../images/bg-sidebar.svg");
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -1.25rem */
    content: "";
    width: 223px;
    height: 55px;
    margin-top: -56px;
    transform: rotate(180deg) scale(1.04);
    background-size: 100%;
    background-image: url(../images/bg-sidebar.svg);
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -17px;
    background-repeat: no-repeat;
}

.neutron-scada .side-nav>ul>li>.side-menu.side-menu--active:after {
    /* content: "";
    width: 30px;
    height: 30px;
    margin-top: 50px;
    transform: scale(1.04);
    background-size: 100%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='259.51' height='259.52' viewBox='0 0 259.51 259.52'%3E%3Cpath id='Path_143' data-name='Path 143' d='M8659.507,423.965c-.167-2.608.05-5.319-.19-8.211-.084-1.012-.031-2.15-.118-3.12-.113-1.25-.1-2.682-.236-4.061-.172-1.722-.179-3.757-.365-5.394-.328-2.889-.478-5.857-.854-8.61-.509-3.714-.825-7.252-1.38-10.543-.934-5.535-2.009-11.312-3.189-16.692-.855-3.9-1.772-7.416-2.752-11.2-1.1-4.256-2.394-8.149-3.687-12.381-1.1-3.615-2.366-6.893-3.623-10.493-1.3-3.739-2.917-7.26-4.284-10.7-1.708-4.295-3.674-8.078-5.485-12.023-1.145-2.493-2.5-4.932-3.727-7.387-1.318-2.646-2.9-5.214-4.152-7.518-1.716-3.16-3.517-5.946-5.274-8.873-1.692-2.818-3.589-5.645-5.355-8.334-2.326-3.542-4.637-6.581-7.039-9.848-2.064-2.809-4.017-5.255-6.088-7.828-2.394-2.974-4.937-5.936-7.292-8.589-3.027-3.411-6.049-6.744-9.055-9.763-2.4-2.412-4.776-4.822-7.108-6.975-3-2.767-5.836-5.471-8.692-7.854-3.332-2.779-6.657-5.663-9.815-8.028-2.958-2.216-5.784-4.613-8.7-6.6-3.161-2.159-6.251-4.414-9.219-6.254-3.814-2.365-7.533-4.882-11.168-6.89-4.213-2.327-8.513-4.909-12.478-6.834-4.61-2.239-9.234-4.619-13.51-6.416-4.1-1.725-8.11-3.505-11.874-4.888-4.5-1.652-8.506-3.191-12.584-4.47-6.045-1.9-12.071-3.678-17.431-5-9.228-2.284-17.608-3.757-24.951-4.9-7.123-1.112-13.437-1.64-18.271-2.035l-2.405-.2c-1.638-.136-3.508-.237-4.633-.3a115.051,115.051,0,0,0-12.526-.227h259.51Z' transform='translate(-8399.997 -164.445)' fill='%23f1f5f8'/%3E%3C/svg%3E%0A"); 
    background-image: url("../images/bg-side.svg");
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -1.25rem */
    content: "";
    width: 233px;
    height: 55px;
    margin-top: 50px;
    /* transform: rotate(180deg) scale(1.04); */
    background-size: 100%;
    background-image: url(../images/bg-sidebar.svg);
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -21px;
    background-repeat: no-repeat;
}

.neutron-scada .side-nav>ul>li>.side-menu.side-menu--active .side-menu__icon {
    color: rgb(var(--color-theme-1) / 1)
}

.neutron-scada .side-nav>ul>li>.side-menu.side-menu--active .side-menu__icon:before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -1.25rem;
    width: 3rem;
    height: 100%;
    background-color: #f1f5f9
}

.neutron-scada .side-nav>ul>li>.side-menu.side-menu--active .side-menu__title {
    font-weight: 500
}

.neutron-scada .side-nav>ul>li>.side-menu:not(.side-menu--active) .side-menu__icon:before {
    content: "";
    z-index: -1;
    width: 230px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(.4, 0, 1, 1);
    transition-duration: .1s
}

@media (max-width: 1279px) {
    .neutron-scada .side-nav>ul>li>.side-menu:not(.side-menu--active) .side-menu__icon:before {
        display: none
    }
}

.neutron-scada .side-nav>ul>li>.side-menu:hover:not(.side-menu--active):not(.side-menu--open) .side-menu__icon:before {
    background-color: #ffffff0d
}

@keyframes intro-divider-animation {
    to {
        opacity: 1
    }
}

@keyframes intro-menu-animation {
    to {
        opacity: 1;
        transform: translate(0)
    }
}

@keyframes intro-submenu-animation {
    to {
        opacity: 1;
        transform: translate(0)
    }
}

@keyframes intro-active-menu-animation {
    to {
        z-index: 10
    }
}

.dark .neutron-scada .side-nav .side-nav__divider {
    background-color: #ffffff12
}

.dark .neutron-scada .side-nav .side-menu {
    color: #cbd5e1
}

.dark .neutron-scada .side-nav>ul ul {
    background-color: rgb(var(--color-darkmode-900) / 30%)
}

.dark .neutron-scada .side-nav>ul ul li a:not(.side-menu--active) {
    color: #94a3b8
}

.dark .neutron-scada .side-nav>ul ul ul {
    background: rgb(var(--color-darkmode-900) / 30%)
}

.dark .neutron-scada .side-nav>ul>li>.side-menu.side-menu--active {
    background-color: rgb(var(--color-darkmode-700) / 1)
}

/* .dark .neutron-scada .side-nav>ul>li>.side-menu.side-menu--active .side-menu__icon {
    color: #cbd5e1
} */

/* .dark .neutron-scada .side-nav>ul>li>.side-menu.side-menu--active .side-menu__icon:before {
    background-color: rgb(var(--color-darkmode-700) / 1)
} */

/* .dark .neutron-scada .side-nav>ul>li>.side-menu.side-menu--active .side-menu__title {
    color: #cbd5e1
}

.dark .neutron-scada .side-nav>ul>li>.side-menu .side-menu__icon {
    color: #94a3b8
}

.dark .neutron-scada .side-nav>ul>li>.side-menu .side-menu__title {
    color: #94a3b8
} */

.dark .neutron-scada .side-nav>ul>li>.side-menu:hover:not(.side-menu--active):not(.side-menu--open) .side-menu__icon:before {
    background-color: rgb(var(--color-darkmode-500) / 70%)
}

.dark .neutron-scada .side-nav>ul>li>.side-menu.side-menu--active:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='259.51' height='259.52' viewBox='0 0 259.51 259.52'%3E%3Cpath id='Path_143' data-name='Path 143' d='M8659.507,423.965c-.167-2.608.05-5.319-.19-8.211-.084-1.012-.031-2.15-.118-3.12-.113-1.25-.1-2.682-.236-4.061-.172-1.722-.179-3.757-.365-5.394-.328-2.889-.478-5.857-.854-8.61-.509-3.714-.825-7.252-1.38-10.543-.934-5.535-2.009-11.312-3.189-16.692-.855-3.9-1.772-7.416-2.752-11.2-1.1-4.256-2.394-8.149-3.687-12.381-1.1-3.615-2.366-6.893-3.623-10.493-1.3-3.739-2.917-7.26-4.284-10.7-1.708-4.295-3.674-8.078-5.485-12.023-1.145-2.493-2.5-4.932-3.727-7.387-1.318-2.646-2.9-5.214-4.152-7.518-1.716-3.16-3.517-5.946-5.274-8.873-1.692-2.818-3.589-5.645-5.355-8.334-2.326-3.542-4.637-6.581-7.039-9.848-2.064-2.809-4.017-5.255-6.088-7.828-2.394-2.974-4.937-5.936-7.292-8.589-3.027-3.411-6.049-6.744-9.055-9.763-2.4-2.412-4.776-4.822-7.108-6.975-3-2.767-5.836-5.471-8.692-7.854-3.332-2.779-6.657-5.663-9.815-8.028-2.958-2.216-5.784-4.613-8.7-6.6-3.161-2.159-6.251-4.414-9.219-6.254-3.814-2.365-7.533-4.882-11.168-6.89-4.213-2.327-8.513-4.909-12.478-6.834-4.61-2.239-9.234-4.619-13.51-6.416-4.1-1.725-8.11-3.505-11.874-4.888-4.5-1.652-8.506-3.191-12.584-4.47-6.045-1.9-12.071-3.678-17.431-5-9.228-2.284-17.608-3.757-24.951-4.9-7.123-1.112-13.437-1.64-18.271-2.035l-2.405-.2c-1.638-.136-3.508-.237-4.633-.3a115.051,115.051,0,0,0-12.526-.227h259.51Z' transform='translate(-8399.997 -164.445)' fill='%23232e45'/%3E%3C/svg%3E%0A")
}

.dark .neutron-scada .side-nav>ul>li>.side-menu.side-menu--active:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='259.51' height='259.52' viewBox='0 0 259.51 259.52'%3E%3Cpath id='Path_143' data-name='Path 143' d='M8659.507,423.965c-.167-2.608.05-5.319-.19-8.211-.084-1.012-.031-2.15-.118-3.12-.113-1.25-.1-2.682-.236-4.061-.172-1.722-.179-3.757-.365-5.394-.328-2.889-.478-5.857-.854-8.61-.509-3.714-.825-7.252-1.38-10.543-.934-5.535-2.009-11.312-3.189-16.692-.855-3.9-1.772-7.416-2.752-11.2-1.1-4.256-2.394-8.149-3.687-12.381-1.1-3.615-2.366-6.893-3.623-10.493-1.3-3.739-2.917-7.26-4.284-10.7-1.708-4.295-3.674-8.078-5.485-12.023-1.145-2.493-2.5-4.932-3.727-7.387-1.318-2.646-2.9-5.214-4.152-7.518-1.716-3.16-3.517-5.946-5.274-8.873-1.692-2.818-3.589-5.645-5.355-8.334-2.326-3.542-4.637-6.581-7.039-9.848-2.064-2.809-4.017-5.255-6.088-7.828-2.394-2.974-4.937-5.936-7.292-8.589-3.027-3.411-6.049-6.744-9.055-9.763-2.4-2.412-4.776-4.822-7.108-6.975-3-2.767-5.836-5.471-8.692-7.854-3.332-2.779-6.657-5.663-9.815-8.028-2.958-2.216-5.784-4.613-8.7-6.6-3.161-2.159-6.251-4.414-9.219-6.254-3.814-2.365-7.533-4.882-11.168-6.89-4.213-2.327-8.513-4.909-12.478-6.834-4.61-2.239-9.234-4.619-13.51-6.416-4.1-1.725-8.11-3.505-11.874-4.888-4.5-1.652-8.506-3.191-12.584-4.47-6.045-1.9-12.071-3.678-17.431-5-9.228-2.284-17.608-3.757-24.951-4.9-7.123-1.112-13.437-1.64-18.271-2.035l-2.405-.2c-1.638-.136-3.508-.237-4.633-.3a115.051,115.051,0,0,0-12.526-.227h259.51Z' transform='translate(-8399.997 -164.445)' fill='%23232e45'/%3E%3C/svg%3E%0A")
}

/* Tambahan */